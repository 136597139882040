/* eslint-disable spaced-comment */
/* eslint-disable no-bitwise */
import { Coordinates, DeepPartial, Dimensions, GoogleMapsCoordinates } from '@common/types';
import { AxiosError } from 'axios';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import stringify from 'json-stable-stringify';
import React from 'react';

// Utility method to strip all typing-shenanigans from onChange-handlers of input-elements
export const handleOnChange = (handler: (value: string) => void) => {
    return (event: React.ChangeEvent<HTMLElement>): void => {
        handler((event.target as HTMLInputElement).value);
    };
};

/* https://decipher.dev/30-seconds-of-typescript/docs/stableSort/ */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const stableSort = <T = any>(arr: T[], compare: (a: T, b: T) => number): T[] => {
    // eslint-disable-next-line implicit-arrow-linebreak
    return arr
        .map((item, index) => ({ item, index }))
        .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
        .map(({ item }) => item);
};

// Returns a hash (int between 0 & 4294967295) for a given string
/* https://www.npmjs.com/package/string-hash */
const stringHash = (str: string): number => {
    let hash = 5381;
    let i = str.length;
    while (i) hash = (hash * 33) ^ str.charCodeAt(--i);
    return hash >>> 0;
};

// Returns a hash (int between 0 & 4294967295) for a given json object
// The JSON-object is stringified
export const jsonHash = (json: object): number => {
    return stringHash(stringify(json) || '');
};

// Compares lead.waterLoadCalculationData without the calculations-values.
// (these can be modified for Specify externally, here we just compare the input-data)
const calcRemover = /"calculations":{(.*?)},/gm;
export const areDhwValuesEqual = (a: unknown, b: unknown): boolean => {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return (
        (stringify(a) || '').replace(calcRemover, '') ===
        (stringify(b) || '').replace(calcRemover, '')
    );
};

export const injectScript = (
    id: string,
    src: string,
    successCallback?: () => void,
    failureCallback?: (e: unknown) => void,
    innerHtml?: string,
): void => {
    if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = src;
        script.id = id;
        if (innerHtml) script.innerHTML = innerHtml;
        // eslint-disable-next-line no-console
        script.onload = () => {
            // eslint-disable-next-line no-console
            console.info(`${id}--script is loaded! 👍🏻`);
            if (successCallback) successCallback();
        };
        script.onerror = (e) => {
            console.error(`error loading ${id}-script`, e);
            if (failureCallback) failureCallback(e);
        };
        document.head.append(script);
    }
};

/*
Correct a list of percentages to have a minimum value
Eg.: values: 80%, 15%, 5% // minimum = 9% -> corrected to: 76.6%, 14.4%, 9%
5 was corrected to 9. 80 & 15 were corrected relatively to their own size to 76.6 & 14.4
*/
type Percentage = { id: string; value: number };
export const limitPercentageToMinimums = (
    percentages: Percentage[],
    minimum: number,
    allowZero = true,
): Percentage[] => {
    // Sort the percentages in increasing value
    percentages = percentages.sort((a, b) => a.value - b.value);
    // Loop over the percentages a first time
    // - increase value to the minimum if they are below the threshold
    // - keep track of the total actual small values and the total offset
    let actualTotal = 1;
    let correctedTotal = 1;
    percentages = percentages.map((perc) => {
        if (allowZero && perc.value === 0) return perc;
        if (perc.value >= minimum) return perc;
        // Add true
        actualTotal -= perc.value;
        correctedTotal -= minimum;
        return { ...perc, value: minimum };
    });
    // Calculate offset for each remaining item
    if (correctedTotal > 0) {
        percentages = percentages.map((perc) => {
            if (perc.value <= minimum) return perc;
            // Decrease large percentages relative to their own size
            return { ...perc, value: (perc.value / actualTotal) * correctedTotal };
        });
    }
    return percentages;
};

// Convert string A++ to a-plus-plus (ENERGY_LABELS/DHW_ENERGY_LABELS)
export const formatAsLongEnergyLabel = (rating: string | undefined): string | undefined => {
    if (!rating) return undefined;
    return rating.replaceAll(/\+/g, '-plus').toLowerCase();
};

// Converts a-plus-plus to A++
export const formatAsShortEnergyLabel = (rating: string): string => {
    return rating.replaceAll('plus', '+').replaceAll('-', '').toUpperCase();
};

export const getCurrentUrl = (keepQuery = false, keepHash = false): string => {
    const { protocol, host, pathname, search, hash } = window.location;
    const parts = [protocol, '//', host, pathname];
    if (keepQuery && search) parts.push(search);
    if (keepHash && hash) parts.push(hash);
    return parts.join('');
};

// Round numbers to have n-number of decimals
export const round = (value: number, decimals = 0): number => {
    // eslint-disable-next-line prefer-template
    return +(Math.round(Number(`${value}e+${decimals}`)) + `e-${decimals}`);
};

export const forceAsNumber = (value: string | number): number => {
    return +value.toString().replace(',', '.');
};

// Returns an array of given length with indexes as values: (5) => [0, 1, 2, 3, 4]
export const indexArray = (length: number): number[] => [...Array(length).keys()];

// Type narrowing for number values
export const isNumber = (value: unknown): value is number => {
    return typeof value === 'number' && !Number.isNaN(value);
};

// Restricts a number to min & max bounds
export const restrict = <T extends number | null | undefined>(value: T, min: T, max: T): T => {
    if (!isNumber(value)) return value;
    if (isNumber(max) && value > max) return max;
    if (isNumber(min) && value < min) return min;
    return value;
};

// Selects all current input when clicking an input-field
export const selectOnFocus = (event: React.FocusEvent<HTMLInputElement, Element>): void => {
    event.target.select();
};

// Deeply compares 2 (nested) arrays. Returns true if equal, false if not.
export const arraysEqual = (arr1: unknown, arr2: unknown): boolean => {
    if (!(arr1 instanceof Array)) return false;
    if (!(arr2 instanceof Array)) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0, l = arr1.length; i < l; i++) {
        if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
            if (!arraysEqual(arr1[i], arr2[i])) {
                return false;
            }
        } else if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
};

export const toggleArrValue = <S>(arr: S[] | undefined, val: S): S[] => {
    if (!arr) return [val];
    return arr.includes(val) ? arr.filter((v) => v !== val) : [...arr, val];
};

export const shallowCompare = (
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>,
): boolean => {
    if (!obj1 && !obj2) {
        return obj1 === obj2; // Both are null/undefined/...
    } else if (!obj1 || !obj2) {
        return false; // One of the two is null/undefined/...
    } else {
        // Both are defined, fetch their keys
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        // Simple key-count check
        if (keys1.length !== keys2.length) return false;
        // Check if keys are equal
        const uKeys = [...new Set([...keys1, ...keys2])];
        if (uKeys.length !== keys1.length) return false;
        // Check if values are equal
        if (uKeys.some((key) => obj1[key] !== obj2[key])) return false;
    }
    return true; // All checks passed
};

export const tryParseBoolean = (
    value: string | boolean | null | undefined,
): string | boolean | null | undefined => {
    if (value === 'true' || value === 'True' || value === 'TRUE') return true;
    else if (value === 'false' || value === 'False' || value === 'FALSE') return false;
    else return value;
};

// Removes all keys from an Object which have null or undefined as value
export const cleanObject = (
    obj: Record<string, unknown>,
    recursive: boolean = true,
    emptyStringRemoval: boolean = false,
): DeepPartial<Record<string, unknown>> => {
    // We are doing destructive changes. Create a shallow copy of the original
    const copy: Record<string, unknown> = { ...obj };
    Object.keys(copy).forEach((key) => {
        const value = copy[key];
        if (value === null || value === undefined || (emptyStringRemoval && value === '')) {
            // Delete the unused key
            delete copy[key];
        } else if (recursive && value instanceof Object && !(value instanceof Array)) {
            // Also remove unused keys from nested Objects (leave Arrays as is)
            copy[key] = cleanObject(
                value as Record<string, unknown>,
                recursive,
                emptyStringRemoval,
            );
        }
    });
    return copy;
};

export const templateString = (
    template: string,
    values: Record<string, string | number>,
): string => {
    template = template || '';
    values = values || {};
    Object.keys(values).forEach((key) => {
        template = template.replace(`{${key}}`, values[key].toString());
    });
    return template;
};

// TS compliant version of filter(Boolean)
export const TsBoolean = <T>(value: T): value is NonNullable<T> =>
    value !== null && value !== undefined;

export const transpose = <T>(mtx: T[][]): T[][] => mtx[0].map((_, c) => mtx.map((r) => r[c]));

// Check if coordinates are valid, returns coords if true & null when false
export const validateCoordinates = (coords: Coordinates | null): Coordinates | null => {
    if (!coords) return null;
    // Check for null or undefined
    const latitude = Number.parseFloat(`${coords.latitude}`);
    const longitude = Number.parseFloat(`${coords.longitude}`);
    if (Number.isNaN(latitude)) return null;
    if (Number.isNaN(longitude)) return null;
    return { latitude, longitude };
};

export const toGoogleMapsCoordinates = (
    coords: Coordinates | GoogleMapsCoordinates,
): GoogleMapsCoordinates => ({
    lat: +((coords as Coordinates).latitude || (coords as GoogleMapsCoordinates).lat),
    lng: +((coords as Coordinates).longitude || (coords as GoogleMapsCoordinates).lng),
});

export const toCoordinates = (coords: Coordinates | GoogleMapsCoordinates): Coordinates => ({
    latitude: +((coords as GoogleMapsCoordinates).lat || (coords as Coordinates).latitude),
    longitude: +((coords as GoogleMapsCoordinates).lng || (coords as Coordinates).longitude),
});

export const snakeToCamel = (str: string): string => {
    return str.replace(/(_\w)/g, (m) => m[1].toUpperCase());
};
export const snakeToDash = (str: string): string => str.replace(/_/g, '-');

export const dashToCamel = (str: string): string => {
    return str.replace(/(-\w)/g, (m) => m[1].toUpperCase());
};
export const dashToSnake = (str: string): string => str.replace(/-/g, '_');

export const camelToSnake = (str: string): string => {
    return str.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toLowerCase();
};
export const camelToDash = (str: string): string => {
    return str.replace(/[\w]([A-Z])/g, (m) => `${m[0]}-${m[1]}`).toLowerCase();
};
// Only perform dashToSnake if the input is actually ke-bab-case (so ignore input that is "-12 m" for example)
export const getAaltraLabelKey = (input: string): string => {
    if (/^[a-z]+(?:-[a-z]+)*$/g.test(input)) return dashToSnake(input);
    if (/^[a-z]+(?:[A-Z][a-z]*)*$/g.test(input)) return camelToSnake(input);
    return input;
};

// Pad a number with zeroes: 000n
export const addLeadingZeros = (num: number, size = 2): string => {
    const s = `000000000${num}`;
    return s.substring(s.length - size);
};

export const validateEmail = (email: string | null): boolean => {
    // eslint-disable-next-line
    return (
        !email ||
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        )
    );
};

export const validatePhone = (phone: string | null): boolean => {
    if (!phone) return true;
    const numberOfDigits = phone.replace(/[^0-9]/gi, '').length;
    // eslint-disable-next-line
    const stripped = phone.replace(/[^0-9\.\/\ \+\(\)]/gi, '');
    return numberOfDigits >= 6 && stripped.length === phone.length;
};

///////////////////////////////////////////////////
//// NUMBER MANIPULATION & MATH UTILITIES /////////
///////////////////////////////////////////////////

// Counts the number of decimals in a number (also accounts for scientific notation eg.: 10e2)
export const countDecimals = (value: number): number => {
    const match = value.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) return 0;
    const decimals = match[1]?.length ?? 0;
    const exponent = match[2] ? -parseInt(match[2], 10) : 0; // parse & invert (negative = decimals)
    return Math.max(0, decimals + exponent);
};

// Rounds a value down to a given step. Eg.:
// Value = 123.56 | Step = 0.1 | Result = 123.6
// Value = 123.45 | Step = 25  | Result = 125
export const roundToStep = (value: number, step: number): number => {
    return round(round(value / step) * step, countDecimals(step));
};

// Round the highest digit up to a fraction (fract) of that digit
// For example:
// (112, .5) => 150
// (16200, .25) => 17500
// (16200, .50) => 20000
export const ceilFract = (value: number, fract = 0.5): number => {
    const D = 10 ** (value.toString().length - 1);
    return (Math.floor(value / D / fract) * fract + fract) * D;
};

///////////////////////////////////////////////////
//// BROWSER UTILITIES ////////////////////////////
///////////////////////////////////////////////////

export const isMobile = (): boolean => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );
};

export const getWindowSize = (): { width: number; height: number } => {
    if (!('innerWidth' in window)) {
        const targetEle = document.documentElement || document.body;
        return { width: targetEle.clientWidth, height: targetEle.clientHeight };
    } else {
        return { width: window.innerWidth, height: window.innerHeight };
    }
};

// Attach query params to url (but checks for existing params)
export const attachQueryString = (
    url: string,
    params: Record<string, unknown>,
    emptyAsString = false,
): string => {
    const parts: string[] = [];
    Object.keys(params).forEach((key) => {
        if (params[key] || emptyAsString) parts.push(`${key}=${params[key]}`);
    });
    // Check if url already has a queryString
    return url + (url.includes('?') ? '&' : '?') + parts.join('&');
};

// Retrieve url query param
export const getUrlParam = (key: string): string => {
    const regex = new RegExp(`[\\?&]${key}=([^&]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1]);
};

export const jsonToFormQuery = (json: Record<string, unknown>): string => {
    const queries: string[] = [];
    // Appends a key to an existing form-key
    const genFormKey = (prefix: string, key: string): string =>
        prefix ? `${prefix}[${key}]` : key;
    // Loops over json and converts each value to a separate query
    const parse = (j: object, prefix = ''): void => {
        Object.entries(j).forEach(([key, value]) => {
            if (value !== null && typeof value === 'object') {
                parse(value, genFormKey(prefix, key));
            } else if (value !== undefined) {
                queries.push(encodeURI(`${genFormKey(prefix, key)}=${value}`));
            }
        });
    };
    parse(json);
    return queries.join('&');
};

export const forceAbsoluteUrl = (url: string): string => (url.includes('//') ? url : `//${url}`);

export const htmlToEditorState = (htmlStr: string): EditorState => {
    if (htmlStr) {
        const contentBlock = htmlToDraft(htmlStr);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            return EditorState.createWithContent(contentState);
        }
    }
    return EditorState.createEmpty();
};

export const editorStateToHtml = (editorState: EditorState): string | null => {
    const htmlStr = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
    if (htmlStr === '<p></p>') return null; // Clearing the editor leaves an empty p-tag behind
    return htmlStr;
};

export const swapArrayIndexes = <T>(array: T[], i1: number, i2: number): T[] => {
    const copy = [...array];
    [copy[i1], copy[i2]] = [copy[i2], copy[i1]];
    return copy;
};

// Helper consts
const rad2 = Math.PI * 2;
// This method is destructive and WILL MODIFY the passed parameters
export const dedupeCoords = <D>(
    items: Array<{ longitude: number; latitude: number; data: D }>,
    radius: number = 0.0001,
    dealersPerCircle: number = 8,
): void => {
    // Group items by lat-lng
    const dupes: Record<string, Array<{ longitude: number; latitude: number; data: D }>> = {};
    items.forEach((item) => {
        const id = `${item.latitude}-${item.longitude}`;
        dupes[id] = dupes[id] ? [...dupes[id], item] : [item];
    });

    // All items which have duplicate-coords should be spread out
    Object.values(dupes)
        .filter((d) => d.length > 1)
        .forEach((d) => {
            const sliceAngle = rad2 / Math.min(dealersPerCircle, d.length);
            d.forEach((p, i) => {
                // Divide into circles with x number of dealers per circle
                const circleIndex = Math.floor(i / dealersPerCircle);
                const circleIsOdd = circleIndex % 2 === 1;
                // Increase the radius for every circle
                const R = radius + circleIndex * 0.00015;
                // For every odd circle we offset the markers with half a slice
                const angleOffset = circleIsOdd ? sliceAngle / 2 : 0;
                const angle = angleOffset + sliceAngle * i;
                p.latitude = +p.latitude + Math.sin(angle) * R;
                p.longitude = +p.longitude + Math.cos(angle) * R;
            });
        });
};

export const formatDimensions = (dimensions: Dimensions): string => {
    const { height, width, depth } = dimensions;
    return `${height}x${width}x${depth} (hxwxd)`;
};

// For UK store postalCode with one space placed before the suffix of 3 characters, see DAI001-8668
export const formatUKPostalCode = (postalCode: string): string => {
    const modifiedPostalCode = postalCode.replaceAll(' ', '');
    if (modifiedPostalCode.length <= 3) return postalCode;
    // Format UK postalCodes if length is greater then 3
    const splitIndex = modifiedPostalCode.length - 3;
    const firstPart = modifiedPostalCode.slice(0, splitIndex);
    const secondPart = modifiedPostalCode.slice(splitIndex);
    return `${firstPart} ${secondPart}`;
};

export const getMaxHeight = (...heights: Array<number | 'auto'>): number | 'auto' => {
    // Check if every value is of type number
    if (heights.every((h) => typeof h !== 'number')) return 'auto';
    // Only return the highest value of the values that are numbers
    return Math.max(...(heights.filter((h) => typeof h === 'number') as Array<number>));
};

export const isAxiosError = (error: unknown): error is AxiosError => {
    return (error as AxiosError).isAxiosError !== undefined;
};

export const isValidUrl = (url: string): boolean => {
    try {
        const valid = new URL(url);
        return valid.protocol === 'https:'; // Only allow HTTPS URLs
    } catch (_) {
        return false;
    }
};

// Return total number of pages for the given translations
export const getPageCount = (pageLength: number, itemsPerPage: number): number => {
    return Math.ceil(pageLength / itemsPerPage);
};
